<template>
    <v-card class="ma-4">
        <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
                   elevation="1">
            <v-toolbar-title> {{ selectedItems.length ? `Selected (${selectedItems.length})  rows` : title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
                    v-if="!selectedItems.length"
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>
            <v-btn
                    v-if="selectedItems.length"
                    color="grey darken-2"
                    dark
                    @click.stop="restoreItems"
            >Restore
            </v-btn>
            <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="mx-11"
                            color="#082d3c"
                            dark
                            v-bind="attrs"
                            v-on="on"

                    >New
                    </v-btn>
                </template>

                <v-card v-if="dialogAddItem">
                    <v-toolbar
                            color="blue-grey"
                            dark
                    >
                        <v-btn
                                dark
                                icon
                                @click="dialogAddItem = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    :disabled="(!valid || buttonLoading)"
                                    :loading="buttonLoading"
                                    dark
                                    text
                                    @click="validate"
                            >Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-alert
                            v-if="Object.keys(errors).length>0"
                            dense
                            text
                            type="error"
                    >
                        <h4 class="subtitle">Correct the following errors:</h4>
                        <ul>
                            <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                            >{{ error.message || error }}
                            </li>
                        </ul>
                    </v-alert>


                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col v-if="imageUrl!=null" cols="12">
                                        <v-img
                                                :src="imageUrl"
                                                class="grey darken-4"
                                                height="200"
                                                style=" border-radius: 15px; margin:auto"
                                                width="200"
                                        ></v-img>
                                    </v-col>

                                    <v-col cols="12">

                                        <v-text-field v-model="name" :counter="150" :rules="nameRules" clearable label="Question/Choice name"
                                                      outlined required></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                        <v-text-field v-model="orderNum" :rules="[v => (!!v || v >=0) || 'Please insert order question/choice']"
                                                      label="Order Question/choice"
                                                      min="0"
                                                      outlined
                                                      required
                                                      type="number"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                        <v-select
                                                v-model="itemParent"
                                                :items="itemParents"
                                                :rules="[v => !!v || 'Please select root of Question']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Parent Question/Choice"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>


                                    <v-col cols="12" md="6" sm="6">
                                        <v-select
                                                v-model="itemDestination"
                                                :items="itemDestinations"
                                                :rules="[v => !!v || 'Please select destination of Choice']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Destination Choice"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isQuestion"
                                                color="red"
                                                label="Is Type Question ?"
                                                @click="changeIsQuestion()"
                                        ></v-checkbox>
                                    </v-col>

                                    <v-col v-if="!isQuestion" cols="12" md="4" sm="6">
                                        <v-select

                                                v-model="optStatus"
                                                :items="optStatuses"
                                                :rules="[v => !!v || 'Please select option status at end']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Option status at end"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>


                                    <v-col v-if="!isQuestion" cols="12" md="4" sm="6">
                                        <v-select
                                                v-model="dependenceType"
                                                :items="dependenceTypes"
                                                :rules="[v => !!v || 'Please select dependence type']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Dependence type"
                                                outlined
                                                required
                                                return-object

                                        ></v-select>
                                    </v-col>

                                    <v-col v-if="!isQuestion" cols="12" md="4" sm="6">
                                        <v-select
                                                v-model="dependence"
                                                :items="dependencies"
                                                :rules="dependenceType.id==0?[]:[v => !!v || 'Please select dependence']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Dependence"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="4" sm="6">
                                        <v-checkbox
                                                v-model="isActive"
                                                color="red"
                                                label="is Question/Choice active"
                                        ></v-checkbox>
                                    </v-col>


                                    <v-col cols="12" md="4" sm="6">
                                        <v-checkbox
                                                v-model="isShowHelpText"
                                                color="red"
                                                label="Is show help text ?"
                                        ></v-checkbox>
                                    </v-col>

                                    <v-col cols="12" md="4" sm="6">
                                        <v-checkbox
                                                v-model="isShowHelpVideo"
                                                color="red"
                                                label="Is show help video ?"
                                        ></v-checkbox>
                                    </v-col>

                                  <v-col v-if="isShowHelpText" cols="12">
                                    <v-tiptap v-model="helpText"/>
                                  </v-col>

                                  <v-col v-if="isShowHelpVideo" cols="12">
                                        <v-text-field v-model="helpVideo" :rules="fieldRules" clearable
                                                      filled label="Help video" required></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="12" sm="12">

                                        <v-file-input
                                                v-model="image"
                                                :rules="imageRules"
                                                accept="image/png, image/jpeg, image/svg+xml"
                                                label="Avatar"
                                                outlined
                                                placeholder="Profile Image"
                                                prepend-icon="mdi-camera"
                                        ></v-file-input>
                                    </v-col>

                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>
            <v-btn
                    v-else
                    class="mx-11"
                    color="grey darken-2"
                    dark
                    @click.stop="showDialogDelete"
            >Delete
            </v-btn>
            <v-btn
                    v-if="selectedItems.length"
                    icon
                    @click="selectedItems = []"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-menu
                    v-else
                    :close-on-content-click="false"
                    :nudge-width="250"
                    offset-x
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                        v-model="isShowDeleted"
                                        color="indigo"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                        v-model="isForceDeleted"
                                        color="indigo"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-toolbar>
        <v-card-text>
            <v-dialog
                    v-model="dialogDelete"
                    max-width="425"
                    persistent
            >
                <v-card>
                    <v-card-title>
                        Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
                    </v-card-title>

                    <v-card-text class=" ">
                        Are you sure for continue deleting these data ?
                    </v-card-text>

                    <v-card-actions>
                        <v-checkbox
                                v-model="isForceDeletedRow"
                                :disabled=disableChangeDelete
                                :ripple=false
                                class="ma-2 red--text "
                                color="red"
                                hide-details
                        >
                            <template v-slot:label>
                                <div class="red--text body-2">
                                    Delete data forever
                                </div>
                            </template>
                        </v-checkbox>

                        <v-spacer></v-spacer>

                        <v-btn
                                :disabled="buttonLoading"
                                class="body-2"
                                color="primary"
                                text
                                @click="hideDialogDelete()"
                        >
                            Reject
                        </v-btn>

                        <v-btn
                                :disabled="buttonLoading"
                                class="body-2"
                                color="red darken-2"
                                text
                                @click="destroyItems"
                        >
                            Process
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                    v-if="!dialogDelete && !dialogAddItem"
                    v-model="buttonLoading"
                    hide-overlay
                    persistent
                    width="300"
            >
                <v-card
                        color="indigo"
                        dark
                >
                    <v-card-text>
                        <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

                        <v-progress-linear
                                class="my-2"
                                color="white"
                                indeterminate
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-data-table
                    v-model="selectedItems"
                    :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                    :headers="headers"
                    :item-class="(item)=>item.deletedAt?'deletedRow':null"
                    :items="desserts"
                    :items-per-page="10"
                    :loading="loading"
                    :options.sync="options"
                    :search="search"
                    checkbox-color="'blue'"
                    item-key='id'
                    show-select

            >


                <template v-slot:header.data-table-select="{props,on}">
                    <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
                </template>
                <template v-slot:item.data-table-select="{isSelected,select}">
                    <v-simple-checkbox :ripple=false :value="isSelected" color="primary" @input="select($event)"></v-simple-checkbox>
                </template>

                <template v-slot:item.id="{ item }">
                    <v-chip>{{ item.id }}</v-chip>
                </template>

                <template v-slot:item.deletedAt="{ item }">
                    <v-icon
                            v-if="item.deletedAt"
                            class="mr-3"
                            color="primary"
                            small
                            @click="restoreItems(item)"
                    >mdi-restore
                    </v-icon>


                    <v-chip
                            color="indigo"
                            label
                            outlined
                            small
                            @click="editItem(item)"
                    >
                        <v-icon small>
                            mdi-pencil
                        </v-icon>
                    </v-chip>


                    <v-chip
                            :color="item.deletedAt ?'orange':'red accent-4'"
                            class="ml-3"
                            label
                            outlined
                            small
                            @click="showDialogDelete(item)"
                    >
                        <v-icon small>
                            {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
                        </v-icon>
                    </v-chip>

                </template>

                <template v-slot:item.parentID="{ item }">
                    <v-chip color="deep-purple" dark>{{ getParentQuestion(item) }}
                    </v-chip>
                </template>

                <template v-slot:item.destinationID="{ item }">
                    <v-chip color="primary" dark>{{ getDestinationQuestion(item) }}</v-chip>
                </template>

                <template v-slot:item.orderNum="{ item }">
                    <v-chip dark>{{ item.orderNum }}</v-chip>
                </template>

                <template v-slot:item.name="{ item }">
                    <v-chip>{{ item.name }}</v-chip>
                </template>

                <template v-slot:item.isActive="{ item }">
                    <v-icon :color="(item.isActive)?'green':'red'">
                        mdi-eye
                    </v-icon>
                </template>

                <template v-slot:item.isQuestion="{ item }">
                    <v-icon :color="(item.isQuestion)?'orange':'blue'">
                        mdi-timeline-question-outline
                    </v-icon>
                </template>

                <template v-slot:item.optStatus="{ item }">
                    <v-chip orange>{{ getOptStatusName(item) }}</v-chip>
                </template>

            </v-data-table>
        </v-card-text>

    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {pathImageUrl, urlBoilerTypes, urlCreateQuestion, urlFuelTypes, urlPrices, urlQuestions, urlSizes, urlUpdateQuestion} from "@/config";

export default {
    data: () => ({
        search: null,
        dialogDelete: false,
        disableChangeDelete: false,
        deleteItemIDs: [],
        selectedItems: [],
        valid: true,
        id: 0,
        name: '',
        nameRules: [
            v => !!v || 'name is required',
            v => (v && v.length <= 150) || 'name must be less than 150 characters',
        ],

        isQuestion: true,
        isShowHelpText: false,
        isShowHelpVideo: false,
        helpText: null,
        helpVideo: null,
        orderNum: 0,
        image: null,
        imageRules: [
            v => !v || !v.length || v[0].size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        imageUrl: null,
        isActive: true,
        fieldRules: [v => !!v || 'This Field is required'],
        itemParent: null,
        itemParents: [{name: "Main", id: 0}],
        itemDestination: null,
        itemDestinations: [{name: "No Target", id: 0}],


        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,

        title: "System Questionnaire",

        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: '#', value: 'id'},
            {text: 'Name', value: 'name', align: 'center'},
            {text: 'Type', value: 'isQuestion', align: 'center'},
            {text: 'Order num.', value: 'orderNum', align: 'center'},
            {text: 'Parent', value: 'parentID', align: 'center'},
            {text: 'Destination', value: 'destinationID', align: 'center'},
            {text: 'Option Status', value: 'optStatus', align: 'center'},
            {text: 'Status', value: 'isActive', align: 'center'},
            {text: 'Actions', value: 'deletedAt', align: 'center'},
        ],
        isFormTitleEdit: false,

        optStatus: {id: 0, name: "Normal"},
        optStatuses: [{id: 0, name: "Normal"}, {id: 1, name: "Finished"}, {id: 2, name: "Contact Us"}],

        dependenceType: {id: 0, name: "with out dependence"},
        dependenceTypes: [{id: 0, name: "with out dependence"}, {id: 1, name: "Boiler Type"}, {id: 2, name: "Boiler Price"},
            {id: 3, name: "Boiler Size Bedroom"}, {id: 4, name: "Boiler Size Bathroom"}, {id: 5, name: "Boiler Size Shower"},
            {id: 6, name: "Boiler Size Radiator"}, {id: 7, name: "Fuel Type"},
        ],
        dependence: null,
        dependencies: [],
        dependenceBoilerTypes: [],
        dependenceBoilerSizes: [],
        dependenceBoilerPrices: [],
        dependenceBoilerFuels: [],

    }),

    created() {
        this.getQuestionsFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit question' : 'Add new question'
        },
        isShowDeleted: {
            get() {
                return this.$store.getters.isShowDeleted;
            },
            set(val) {
                this.$store.state.isShowDeleted = val;
                this.getQuestionsFromApi();
                return val;
            }


        },
        isForceDeleted: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.isForceDeletedRow = val;
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },
        isForceDeletedRow: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },
    },

    watch: {
        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },

        dependenceType() {
            this.changeDependence();
        },


    },

    methods: {
        getQuestionsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlQuestions + this.isShowDeleted)
                .then(response => {

                    this.desserts = response.data;
                    this.itemParents = this.itemParents.concat(response.data.filter(c => c.parentID == 0));
                    this.itemDestinations = this.itemDestinations.concat(response.data.filter(c => c.parentID == 0));

                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getBoilerTypesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlBoilerTypes)
                .then(response => {
                    this.dependenceBoilerTypes = this.dependencies = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getBoilerSizesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlSizes)
                .then(response => {
                    this.dependenceBoilerSizes = this.dependencies = response.data.filter((e) => e.parentID > 0);
                    this.dependencies = this.dependenceBoilerSizes;
                    if (this.dependenceType.id === 3) {
                        this.dependencies = this.dependencies.filter((e) => e.type == 0)
                    } else if (this.dependenceType.id === 4) {
                        this.dependencies = this.dependencies.filter((e) => e.type == 1)
                    } else if (this.dependenceType.id === 5) {
                        this.dependencies = this.dependencies.filter((e) => e.type == 2)
                    } else {
                        this.dependencies = this.dependencies.filter((e) => e.type == 3)
                    }
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getBoilerPricesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlPrices)
                .then(response => {
                    this.dependenceBoilerPrices = this.dependencies = response.data.filter((e) => e.parentID > 0);
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getFuelTypesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlFuelTypes)
                .then(response => {
                    this.dependenceBoilerFuels = this.dependencies = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getParentQuestion(item) {

            if (item.parentID == 0)
                return "Main";
            else {
                let parent = this.desserts.filter(c => c.id == item.parentID);
                if (parent.length > 0) {
                    let name = parent[0].name;
                    if (name.length > 30) {
                        return name.substring(0, 30) + '...'
                    } else {
                        return name
                    }
                }
            }

        },

        getDestinationQuestion(item) {

            if (item.destinationID == 0)
                return "No Target";
            else {
                let parent = this.desserts.filter(c => c.id == item.destinationID);
                if (parent.length > 0) {
                    let name = parent[0].name;
                    if (name.length > 30) {
                        return name.substring(0, 30) + '...'
                    } else {
                        return name
                    }
                }
            }

        },

        getOptStatusName(item) {
            let parent = this.optStatuses.filter(c => c.id === item.optStatus);
            if (parent.length > 0)
                return parent[0].name;
        },

        async changeDependence() {
            this.dependencies = [];
            if (this.dependenceType.id === 1) {
                this.dependencies = this.dependenceBoilerTypes;
                if (this.dependencies.length < 1)
                    await this.getBoilerTypesFromApi();

            } else if (this.dependenceType.id === 2) {
                this.dependencies = this.dependenceBoilerPrices;
                if (this.dependencies.length < 1)
                    await this.getBoilerPricesFromApi();

            } else if (this.dependenceType.id === 7) {
                this.dependencies = this.dependenceBoilerFuels;
                if (this.dependencies.length < 1)
                    await this.getFuelTypesFromApi();

            } else {
                this.dependencies = this.dependenceBoilerSizes;
                if (this.dependenceType.id === 3) {
                    this.dependencies = this.dependencies.filter((e) => e.type == 0)
                } else if (this.dependenceType.id === 4) {
                    this.dependencies = this.dependencies.filter((e) => e.type == 1)
                } else {
                    this.dependencies = this.dependencies.filter((e) => e.type == 2)
                }
                if (this.dependencies.length < 1)
                    await this.getBoilerSizesFromApi();

            }

        },

        changeIsQuestion() {
            this.dependenceType = {id: 0, name: "with out dependence"};
            this.optStatus = {id: 0, name: "Normal"};
            this.dependence = null;
        },

        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {

            const fd = new FormData();

            fd.append("name", this.name);
            if (this.image != null) fd.append("avatar", this.image);
            fd.append("orderNum", this.orderNum);
            fd.append("parentID", this.itemParent.id);
            fd.append("destinationID", this.itemDestination.id);
            fd.append("dependenceID", this.dependence?.id ?? 0);
            fd.append("dependenceType", this.dependenceType?.id ?? 0);
            fd.append("optStatus", this.optStatus.id);
            fd.append("isShowHelpText", this.isShowHelpText);
            fd.append("isShowHelpVideo", this.isShowHelpVideo);
            fd.append("helpText", this.helpText);
            fd.append("helpVideo", this.helpVideo);
            fd.append("isQuestion", this.isQuestion);
            fd.append("isActive", this.isActive);


            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateQuestion + this.id, 2, fd,true)
                    .then(response => {

                        if (response.status === 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getQuestionsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {

                networks.fetchFromWeb(urlCreateQuestion, 1, fd,true)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getQuestionsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.name = '';
            this.id = 0;
            this.orderNum = 0;
            this.itemParent = null;
            this.itemDestination = null;
            this.optStatus = {id: 0, name: "Normal"};
            this.dependenceType = {id: 0, name: "with out dependence"};
            this.dependence = null;
            this.isShowHelpText = false;
            this.isShowHelpVideo = false;
            this.helpText = null;
            this.helpVideo = null;
            this.image = null;
            this.imageUrl = null;

            this.isQuestion = true;
            this.isActive = true;

            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {
            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.name = item.name;
            this.orderNum = item.orderNum;
            this.optStatus = {id: item.optStatus};
            this.dependenceType = {id: item.dependenceType};
            this.dependence = {id: item.dependenceID};
            this.isShowHelpText = item.isShowHelpText;
            this.isShowHelpVideo = item.isShowHelpVideo;
            this.helpText = item.helpText;
            this.helpVideo = item.helpVideo;
            this.imageUrl = (item.image != null && item.image) ? pathImageUrl + item.image : null;
            this.itemParent = {id: item.parentID};
            this.itemDestination = {id: item.destinationID};
            this.isQuestion = item.isQuestion;
            this.isActive = item.isActive;
            this.dialogAddItem = true;
        },

        showDialogDelete(item) {
            if (this.selectedItems.length < 1) {
                if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
                    this.disableChangeDelete = item.deletedAt;
                    this.dialogDelete = true;
                    this.deleteItemIDs.push(item.id);
                }
            } else {
                this.dialogDelete = true;
            }

        },
        hideDialogDelete() {
            this.dialogDelete = false;
            this.disableChangeDelete = false;
            this.deleteItemIDs = [];
            this.selectedItems = [];
        },
        destroyItems() {

            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];

                if (this.isForceDeletedRow)
                    currentSelected = this.selectedItems;
                else
                    currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


                currentSelected.map((e) => this.deleteItemIDs.push(e.id));
            }


            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false
            } else {
                networks.fetchFromWeb(urlQuestions + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();
                            alert("Data has been deleted");
                            this.getQuestionsFromApi();
                        }

                    })
                    .catch(() => {
                        alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);
            }

        },

        restoreItems(item) {
            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];
                currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
                currentSelected.map((e) => this.deleteItemIDs.push(e.id));

            } else {
                this.deleteItemIDs.push(item.id);
            }

            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false;
            } else {

                networks.fetchFromWeb(urlQuestions + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();

                            this.getQuestionsFromApi();
                        }

                    })
                    .catch(() => {
                        alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);

            }
        }


    },
}
</script>



